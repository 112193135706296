'use client'; // Error components must be Client Components
import Link from 'next/link';
import { useEffect } from 'react';

import { Typography } from '@/shared/ui/typography';

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography size={'3xl'} weight={'bold'}>
        Something went wrong !
      </Typography>
      <Typography>
        View <Link href="/">Back to Home page</Link>
      </Typography>
    </div>
  );
}
